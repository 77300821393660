import styles from '../../styles/track.module.css';
import StartTrackingButton from '../sharedComponents/StartTrackingButton';
import map from '../../assets/images/map-1.webp';
import phones from '../../assets/images/phones.webp';
import greypath from '../../assets/images/greyPath.webp';
import markers from '../../assets/images/markers.webp';
import envelope from '../../assets/images/envelope.webp';
import scellenvelope from '../../assets/images/scellEnvelope.webp';


const BottomSection = () => {


    const scrollToTop = (e) => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }



    return (
        <div className='bottomContainer'>
            <div className='maxScreen'>
                <div className={styles.bottomHeaderContainer}>
                    <img src={map} width={76} height={76} alt="map_image" loading="lazy" />
                    <h1 className="secondaryHeading fontWhite lgVerticalMargin">Device Tracker Plus comes with a range of great features that deliver complete <span className="hightLightText">peace of mind</span> :</h1>
                </div>
                <div className="bottomGrid">
                    <div className="border">
                        <div onClick={scrollToTop} className="bottomFeature">
                            <img src={phones} width={65} height={48} alt='featureIcon' loading="lazy" />
                            <p className='bottomFeatureParagraph text-white'>Track up to 5 devices in real time, on Android or iOS, so you’ll always know where they are and if they’re safe.</p>
                            <img src={greypath} width={32} height={54} alt='greyChevron' loading="lazy" />
                        </div>
                    </div>
                    <div onClick={scrollToTop} className="bottomFeature">
                        <img src={markers} width={65} height={48} alt='featureIcon' loading="lazy" />
                        <p className='bottomFeatureParagraph text-white'>See a full 30-day location and route history for each user plotted on a map including stop offs and timings.</p>
                        <img src={greypath} width={32} height={54} alt='greyChevron' loading="lazy" />
                    </div>
                    <div onClick={scrollToTop} className="bottomFeature">
                        <img src={envelope} width={65} height={48} alt='featureIcon' loading="lazy" />
                        <p className='bottomFeatureParagraph text-white'>Installs onto existing mobile devices. So there’s no need to buy additional tracking hardware – or make anyone carry it. </p>
                        <img src={greypath} width={32} height={54} alt='greyChevron' loading="lazy" />
                    </div>
                    <div onClick={scrollToTop} className="bottomFeature">
                        <img src={scellenvelope} width={65} height={48} alt='featureIcon' loading="lazy" />
                        <p className='bottomFeatureParagraph text-white'>Set up custom geofences around important locations, and receive notifications when anyone arrives at or leaves these zones. </p>
                        <img src={greypath} width={32} height={54} alt='greyChevron' loading="lazy" />
                    </div>
                </div>
                <div className="buttonContainer">
                    <StartTrackingButton style={`regTrackingButton`} title=" Get Started " />
                </div>
            </div>
        </div>
    );
}

export default BottomSection;