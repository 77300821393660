export const newTrackFeatureList = [
    {
        id: '1',
        title: 'Worried Parents',
        content: 'Your child’s safety is your top priority. With Device Tracker Plus, you can keep tabs on their whereabouts and make sure they are always safe.',
    },
    {
        id: '2',
        title: 'Concerned Family ',
        content: 'Know that your loved ones are safe and where they should be. You’ll have the peace of mind that comes with knowing they are always within reach.',
    },
    {
        id: '3',
        title: 'Lost Phone',
        content: 'As a Device Tracker Plus member, if you lose your phone, you can locate it instantly. Just log into your account from another device and hit Locate.',
    },
    {
        id: '4',
        title: 'Solo Travellers',
        content: 'Keep your family and friends updated on your travels in real time. Whether you’re hiking in the mountains, exploring a new city, or soaking up the sun on the beach, they’ll always know where you are and that you’re safe.',
    },
];