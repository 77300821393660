import React, { useEffect } from 'react';
import styles from '../styles/welcomeFamily.module.css';
import Spinner from '../components/sharedComponents/Spinner';
import { useNavigate } from "react-router-dom";


const DeviceDetection = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const ua = navigator.userAgent.toLowerCase()
        const isAndroid = ua.includes('android')
        console.log(`The user is on an Android: ${isAndroid}`)
        const isIPhone = (navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i))
        console.log(`The user is on an iPhone: ${isIPhone}`)
        if (isAndroid) {
            setTimeout(() => {
                window.location.replace('https://play.google.com/store/apps/details?id=com.bostondigital.devicetrackerplus&hl=en')
            }, 1500)
        } else {
            setTimeout(() => {
                window.location.replace('https://apps.apple.com/gb/app/device-tracker-plus2/id1498985451')
            }, 1500)
        }
    }, [navigate]);



    return (
        <div className={styles.redirectContainer}>
            <h2 className={styles.familyHeader}>We will redirect you to store in a seconds...</h2>
            <Spinner />
        </div>
    )
}

export default DeviceDetection