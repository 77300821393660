import React, { useState, lazy, Suspense } from 'react';
import familyHero_deskImage from '../../assets/images/familyTrackingHero_desk.webp';
import familyHero_mobileImage from '../../assets/images/familyTrackingHero_mobile.webp';
import desktopImage from '../../assets/images/mainBgSmall.webp';
import mobileImage from '../../assets/images/bgMobile.webp';
import style from '../../styles/track.module.css';
import Spinner from '../sharedComponents/Spinner';

const PhoneVerificationComponent = lazy(() => import('../sharedComponents/PhoneVerification'));
const HeroImageComponent = lazy(() => import('../sharedComponents/HeroImageComponent'));

const FamilyTrackingHero = () => {

    const [showSearchInput, setShearchInput] = useState(true);

    const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage;

    const togglePhoneVerificationPopup = (e) => {
        e.preventDefault();
        setShearchInput(!showSearchInput);
    }
    return (
        <section className="mainBgc" style={{ backgroundImage: `url(${imageUrl})` }}>
            <div className="mainContainer">
                <div className="heroContainer">
                    <h1 className="heroHeading">Family Tracking <span className="hightLightText">App</span></h1>
                    <p className={style.trackSubtitle}>Designed with parents and families in mind. Say goodbye to constant check-in texts or worrying about missed updates - our family finder app lets you keep tabs on your loved ones effortlessly. </p>
                    <Suspense fallback={<Spinner />}>
                        {showSearchInput && <PhoneVerificationComponent showPopUp={togglePhoneVerificationPopup} />}
                    </Suspense>
                </div>
                <div>
                    <Suspense fallback={<Spinner />}>
                        <HeroImageComponent desktopImage={familyHero_deskImage} mobileImage={familyHero_mobileImage} />
                    </Suspense>
                </div>
            </div>
        </section>
    );
}

export default FamilyTrackingHero;