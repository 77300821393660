import style from '../../styles/track.module.css';
import StartTrackingButton from '../sharedComponents/StartTrackingButton';
import TestimonialCarousel from './TestimonialCarousel';

const Testimonials = () => {
    return (
        <section className={style.main}>
            <div className='maxScreen'>
                <h2 className="heroHeading" >
                    Over
                    <span className="hightLightText"> 1 million </span>
                    customers.<br />
                    Over
                    <span className="hightLightText"> 15 million </span>
                    devices tracked.<br />
                    Over
                    <span className="hightLightText"> 20 billion </span>
                    locations recorded.
                </h2>
                <br />
                <p className="parahraphCentered midVerticalMargin">Read what our happy customers have to say about Device Tracker Plus.</p>
                <br />
                <div className="midVerticalMargin">
                    <TestimonialCarousel />
                </div>
                <div className="buttonContainer">
                    <StartTrackingButton title="Start tracking now" style={`trackingButton`} />
                </div>
            </div>
        </section>
    );
}

export default Testimonials;