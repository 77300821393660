import style from '../../styles/track.module.css';
import StartTrackingButton from '../sharedComponents/StartTrackingButton';
import map from '../../assets/images/map-1.webp';
import greypath from '../../assets/images/greyPath.webp';

const CampaignBottomSection = ({ sectionHeading, features }) => {

    return (
        <section className="bottomContainer">
            <div className='maxScreen'>
                <div className={style.bottomHeaderContainer}>
                    <img src={map} width={76} height={76} alt="map_image" />
                    <h1 className="secondaryHeading fontWhite lgVerticalMargin">{sectionHeading}:</h1>
                </div>
                <div className="bottomGrid">
                    {features.map(({ imgName, title, partContent, link, linkContent, content }) => (
                        <div className="border">
                            <div className="bottomFeature">
                                <img src={require(`../../assets/images/${imgName}.webp`)} width={65} height={48} alt='featureIcon' />
                                <div>
                                    <h3 className='bottomFeatureTitle'>{title}</h3>
                                    <p className='bottomFeatureParagraph text-white'>{partContent}{link ? <a href={link} style={{ color: '#fff' }}>{linkContent}</a> : null}{content}</p>
                                </div>

                                <img src={greypath} width={32} height={54} alt='greyChevron' />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="buttonContainer">
                    <StartTrackingButton style={`regTrackingButton`} title="Start Tracking Now" />
                </div>
            </div>
        </section>
    );
}

export default CampaignBottomSection;