import styles from '../styles/help.module.css';
import HelpButtons from "../components/help/HelpButtons";
import ContactForm from "../components/help/ContactForm";
import { Helmet } from 'react-helmet';


const Help = () => {

    const mediaUrl = process.env.REACT_APP_PUBLIC_CDN_URL;

    return (
        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
                <link rel="canonical" href="https://www.devicetrackerplus.com/help" />
            </Helmet>
            <div className={styles.mainContainer}>
                <div className={styles.helpCard}>
                    <h1 className={styles.helpHeader}>How can we help you?</h1>
                    <a href="/"><button className={styles.goBackButton}>Go Back Home</button></a>
                    <HelpButtons mediaUrl={mediaUrl} />
                </div>
                <div className={styles.contactFormContainer}>
                    <h1 className={styles.contactFormHeader}>Submit a request for support</h1>
                    <div style={{ width: '100%' }} id="contact-form">
                        <ContactForm />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Help;