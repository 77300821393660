import React, { useState, lazy, Suspense } from 'react';
import desktopImage from '../../assets/images/mainBgSmall.webp';
import mobileImage from '../../assets/images/bgMobile.webp';
import Spinner from '../sharedComponents/Spinner';
import HeroImageComponent from '../sharedComponents/HeroImageComponent';
import directionImage from '../../assets/images/travelImgDesk.webp';
import directionImageMobile from '../../assets/images/travelImg_mobile.webp';

const PhoneVerificationComponent = lazy(() => import('../sharedComponents/PhoneVerification'));

const TrackHero = () => {

    const [showSearchInput, setShearchInput] = useState(true);

    const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage;

    const togglePhoneVerificationPopup = (e) => {
        e.preventDefault();
        setShearchInput(!showSearchInput);
    }
    return (
        <section className="mainBgc" style={{ backgroundImage: `url(${imageUrl})` }}>
            <div className="mainContainer">
                <div className="heroContainer">
                    <br></br>
                    <br></br>
                    <h1 className="heroHeading">Get <span className="hightLightText">peace</span> of mind with <span className="hightLightText"><br></br>the #1 </span>
                        location tracker.</h1>
                    <h3 className="secondaryHeading smallVerticalMargin">Over <span className="hightLightText">5 million</span> users worldwide.</h3>
                    <Suspense fallback={<Spinner />}>
                        {showSearchInput && <PhoneVerificationComponent showPopUp={togglePhoneVerificationPopup} />}
                    </Suspense>
                </div>
                <div>
                    <Suspense fallback={<Spinner />}>
                        <HeroImageComponent desktopImage={directionImage} mobileImage={directionImageMobile} />
                    </Suspense>
                </div>
            </div>
        </section>
    );
}

export default TrackHero;