import styles from '../../styles/track.module.css';
import rays_feature from '../../assets/images/rays_feature.webp';
import { newTrackFeatureList } from '../../data/newTrackFeatures';
import StartTrackingButton from '../sharedComponents/StartTrackingButton';

const TrackFeatures = () => {
    return (
        <section className={styles.main}>
            <div className='maxScreen'>
                <div className={styles.featuremainContainer}>
                    <h1 className={styles.trackFeatureHeading}>Keep Track of Your Loved Ones and Devices with Accurate Mobile Number Tracking</h1>
                    <img src={rays_feature} width={52} height={79} alt="rays_image" className="mobileDisabled" loading="lazy" />
                </div>
                <div className={styles.featureGrid}>
                    {newTrackFeatureList.map((feature) => (
                        <div key={feature.id}>
                            <div className={styles.featureTitle}>{feature.title}</div>
                            <div className={styles.featureContent}>{feature.content}</div>
                        </div>
                    ))}
                </div>
                <div className={styles.buttonContainer}>
                    <StartTrackingButton title="Start Tracking Now" style={`trackingButton`} />
                </div>
            </div>
        </section>
    );
}

export default TrackFeatures;