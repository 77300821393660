import HomeHero from "../components/home/HomeHero";
import Features from "../components/home/Features";
import HowItWorks from "../components/home/HowItWorks";
import BottomSection from "../components/home/BottomSection";
import Testimonials from "../components/home/Testimonials";
import { Helmet } from 'react-helmet';

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
                <link rel="canonical" href="https://www.devicetrackerplus.com/" />
            </Helmet>
            <HomeHero />
            <Features />
            <HowItWorks />
            <BottomSection />
            <Testimonials />
        </>
    );
}

export default Home;