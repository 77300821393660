"use client"
import React, { useEffect, useRef, useState } from 'react';
import Spinner from './Spinner';
import intlTelInput from 'intl-tel-input';
import '../../../src/intlTelInput.css';
import styles from '../../styles/welcomeFamily.module.css';
import QRCode from './QRCode';



let iti;

const FamilySendSmsForm = ({ link }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const phoneInput = useRef({});

    useEffect(() => {
        iti = intlTelInput(phoneInput.current, {
            initialCountry: "auto",
            geoIpLookup: getCountry,
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        });
    }, []);

    function getCountry(callback) {
        fetch(process.env.REACT_APP_PUBLIC_DEVICE_URL + 'Phone/country')
            .then(r => r.text())
            .then(data => callback(data));
    }

    async function sendSms(phoneNumber) {

        setIsLoading(true);
        var data = {
            referenceId: localStorage.getItem('userId'),
            phoneNumber: phoneNumber
        };

        const response = await fetch(process.env.REACT_APP_PUBLIC_DEVICE_URL + 'phone/sendSms',
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });
        if (response.ok) {
            setIsSuccess(true);
        }
        else {
            let data = await response.json();
            if (data.error) {
                setErrorMessage(data.error);
            } else {
                setErrorMessage("Something went wrong. Please contact support.");
            }
        }
        setIsLoading(false);
    }


    function onSmsSubmit(e) {
        e.preventDefault();


        if (!iti.isValidNumber()) {
            setErrorMessage('Invalid phone number');
            return;
        }

        sendSms(iti.getNumber());
    }

    return (
        <>
            {errorMessage ?
                <span className={styles.errorMessage}>{errorMessage}</span>
                : null}
            {isSuccess ?
                <h2>SMS sent!</h2>
                :
                <div className={styles.sendCodePanel}>
                    <div className={styles.smsPanelContainer}>
                        <p className={styles.smsPanelHeader}>Enter your phone number to get started </p>
                        <form onSubmit={onSmsSubmit} className={styles.smsForm}>
                            <input type="tel" name="phone" id="phone" className={styles.emailInput} ref={phoneInput} />
                            <br />
                            <button type="submit" className={styles.btn}>Send SMS </button>
                        </form>
                    </div>
                    <div className={styles.orPanel}>
                        <p className={styles.orfont}>Or</p>
                    </div>
                    <div className={styles.smsPanelContainer}>
                        <p className={styles.smsPanelHeader}>Scan QR code below</p>
                        <QRCode link={link} />
                    </div>

                </div >
            }

            {
                isLoading ?
                    <Spinner />
                    : null
            }
        </>

    )
}

export default FamilySendSmsForm