import { Helmet } from 'react-helmet';
import LocationSharingHero from '../components/campaings/LocationSharingHero';
import CampaignFeatures from '../components/campaings/CampaignFeatures';
import { loactionSharingFeatureList } from '../data/loactionSharingFeatureList';
import HowItWorks from '../components/track/HowItWorks';
import CampaignBottomSection from '../components/campaings/CampaignBottomSection';
import { bottomLoactionSharingFeatures } from '../data/bottomLoactionSharingFeatures';
import Testimonials from '../components/track/Testimonials';
import { loactionSharingTestimonials } from '../data/loactionSharingTestimonials';

const LocationSharingCampaign = () => {
    return (
        <>
            <Helmet>
                <title>Location Sharing App for iPhone & Android - Device Tracker Plus</title>
                <meta name="description" content="Share your live location with friends and family with our accurate location sharing app. Downloadnow, stay connected, and keep peace of mind." />
                <link rel="canonical" href="https://www.devicetrackerplus.com/locationSharing" />
            </Helmet>
            <LocationSharingHero />
            <CampaignFeatures sectionTitle="Share Your Live Location For Ease and Security" features={loactionSharingFeatureList} />
            <HowItWorks />
            <CampaignBottomSection sectionHeading="A Location Sharing App For Everyone" features={bottomLoactionSharingFeatures}/>
            <Testimonials testimonialsData={loactionSharingTestimonials} />
        </>
    );
}

export default LocationSharingCampaign;