
import React, { useEffect, useState } from 'react';
import styles from '../styles/groupCode.module.css';
import EmailForm from '../components/sharedComponents/EmailForm';
import GroupCodePanel from '../components/sharedComponents/GroupCodePanel';
import Spinner from '../components/sharedComponents/Spinner';
import { DtpUtils } from '../utils/DtpUtils';
import StoreButtons from '../components/sharedComponents/StoreButtons';
import { Helmet } from 'react-helmet';

const GroupCode = () => {

    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [groupCode, setGroupCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [deepLink, setDeepLink] = useState("");

    useEffect(() => {

        let params = new URLSearchParams(document.location.search.substring(1));
        let code = params.get('code');
        let link = params.get('link');
        if (code) {
            setGroupCode(code);
        }

        if (link) {
            if (DtpUtils.isMobileBrowser()) {
                setDeepLink(link);
            }
        }

    }, []);

    async function getCode(email) {
        setIsLoading(true);
        const res = await fetch(process.env.REACT_APP_PUBLIC_PAYMENT_URL + "payment/code?email=" + email);
        console.log("Email res: ", res);
        let data = await res.json();

        if (!res.ok) {
            if (data.error) {
                setEmailErrorMessage(data.error);
            } else {
                setEmailErrorMessage("Failed to fetch data. Please try again later or contact support.");
            }
        } else {
            if (data.deviceGroup && data.deviceGroup.code) {
                localStorage.setItem('payment-data', JSON.stringify(data));
                var code = data.deviceGroup.code;
                window.location.replace("/groupcode?code=" + code + "&link=" + data.deviceGroup.invitationLink);
            } else if (data.isPaid) {
                setEmailErrorMessage("Something went wrong. Please contact support.");
            } else {
                setEmailErrorMessage("We found your account but it seems its not active anymore.");
            }
        }
        setIsLoading(false);
    }

    return (
        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
            </Helmet>
            <div className={styles.groupCodeMainBgc}>
                <div className={styles.groupCodeMainContainer}>
                    <div className={styles.headerConstainer}>
                        <h2 className={styles.boldParagraph}>Welcome back to Device Tracker Plus!</h2>
                        <p className={styles.standardParagraph}>Thank you for your purchase, to activate the tracking app on your device you will need to enter this group code when prompted. Please make a note of this now.</p>
                    </div>
                    {groupCode ?
                        <div style={{ margin: '48px' }}>
                            <h3>Your code to DTP mobile app is</h3>
                            <GroupCodePanel groupCode={groupCode} />
                        </div>
                        :
                        <div className={styles.emailFormContainer}>
                            <h3 className={styles.emailFormHeader}>Please enter your email address to get your group code back</h3>
                            <EmailForm btnLabel={"Get the code"} onSubmit={getCode} errorMessage={emailErrorMessage} />
                            {isLoading ?
                                <div className={styles.spinnerContainer}>
                                    <Spinner />
                                </div>

                                : null}
                        </div>
                    }
                    <div className={styles.bottomTextConatiner}>
                        <p className={styles.standardParagraph}>You can use this code to add to up <span style={{ fontWeight: 'bold' }}>5</span> devices to your group.</p>
                        <p className={styles.standardParagraph}>To download the app, please select the appropriate app store for your device</p>
                    </div>
                    <StoreButtons link={deepLink} />
                </div>
            </div>
        </>
    );
}

export default GroupCode;