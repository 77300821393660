import React, { useState, useEffect } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import googleLogo from '../../assets/images/google.webp';
import appleLogo from '../../assets/images/apple.webp';
import styles from '../../styles/welcomeFamily.module.css';

const StoreButtons = ({ link }) => {

    const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");
    const [googleStroeUrl, setgoogleStroeUrl] = useState('https://play.google.com/store/apps/details?id=com.bostondigital.devicetrackerplus&hl=en');
    const [appleStoreUrl, setappleStoreUrl] = useState('https://apps.apple.com/gb/app/device-tracker-plus2/id1498985451');
    const [deepLink, setDeepLink] = useState("");

    useEffect(() => {
        console.log("StoreButtons.Link: ", link);

        if (link && !isAboveMediumScreens) {
            setgoogleStroeUrl(link);
            setappleStoreUrl(link);
            setDeepLink(link);
        }
    }, [link, isAboveMediumScreens]);

    return (
        <>
            {isAboveMediumScreens || !deepLink ?
                <div className={styles.storeButtonsContainer}>
                    <a href={appleStoreUrl} target="blank" className={styles.storelink}>
                        <img src={appleLogo} className={styles.storeLogo} alt="apple_logo" loading="lazy"/>
                    </a>
                    <a href={googleStroeUrl} target="blank" className={styles.storelink}>
                        <img src={googleLogo} className={styles.storeLogo} alt="google_logo" loading="lazy"/>
                    </a>
                </div> :
                <div style={{ marginTop: '24px' }}>
                    <button
                        id="startTracking"
                        className={styles.getStartedButton}
                        onClick={() => window.open(deepLink, '_blank')}
                    >Get started
                        <ArrowRightIcon className={styles.arrowIcon}></ArrowRightIcon>
                    </button>
                </div>
            }
        </>
    );
}

export default StoreButtons;