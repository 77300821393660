import styles from '../styles/terms.module.css';
import { Helmet } from 'react-helmet';

const Terms = () => {

    return (
        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
                <link rel="canonical" href="https://www.devicetrackerplus.com/terms" />
            </Helmet>

            <div className={styles.mainPageContainer}>
                <div className={styles.fullInnerCard}>
                    <h1 className={styles.pageTitle}>Terms and Conditions</h1>
                    <h4 className={styles.pageSubtitle}>Please read these terms if you wish to use the Device Tracker Plus service.</h4>
                    <br />
                    <h4 className={styles.sectionTitleOrange}>1. Introduction</h4>
                    <p className={styles.sectionSubtitle}>1.1 This page tells you the terms and conditions on which we supply Device Tracker Plus (our service) and all information and services on www.devicetrackerplus.com (our site). Please read these terms and conditions carefully and make sure that you understand them, before subscribing. You should understand that by submitting your email address and password on our site, you agree to be bound by these terms and conditions. If you do not agree or cannot understand the terms and conditions, please stop using our services. Your failure to read the terms and conditions will not be an acceptable ground to exclude you from your liabilities arising from the terms and conditions.</p>
                    <p className={styles.sectionSubtitle}>1.2 You should print a copy of these terms and conditions for future reference.</p>
                    <p className={styles.sectionSubtitle}>1.3 By accepting our Terms of Service you consent to Device Tracker Plus (Boston Digital Limited) supplying our service to you immediately, and that by actively giving this express consent you acknowledge that you waive the right to action any statutory consumer cancellation rights.</p>
                    <p className={styles.sectionSubtitle}>1.4 We reserve the right to amend these terms and conditions at any time by giving you notice by posting the amended terms and conditions on our site. However, please note that you will be subject to the terms and conditions in force at the time that you subscribed, unless any change to these terms and conditions is required to be made by law or governmental authority (in which case it will apply to orders previously placed by you).</p>
                    <h4 className={styles.sectionTitleOrange}>2. Your status</h4>
                    <p className={styles.sectionSubtitle}>2.1 By subscribing to a Device Tracker Plus monthly package through our site, you warrant that you are legally capable of entering into binding contracts and you are at least 18 years old.</p>
                    <h4 className={styles.sectionTitleOrange}>3. How the contract between you and us is formed</h4>
                    <p className={styles.sectionSubtitle}>3.1 After selecting a package from Device Tracker Plus and submitting payment details you will receive an e-mail from us acknowledging that we have received your order. The contract will become legally binding once the payment for that package has been accepted.</p>
                    <h4 className={styles.sectionTitleOrange}>4. Service and Payments</h4>
                    <p className={styles.sectionSubtitle}>4.1 Subject to the Terms and Conditions, Device Tracker Plus is available to use for an agreed monthly fee. The fee is dependent on the package chosen at the time of subscribing.</p>
                    <p className={styles.sectionSubtitle}>4.2 Device Tracker Plus is provided for the following purposes:</p>
                    <p className={styles.sectionSubtitle}>4.2.1 To track your lost stolen mobile – please note you must have the app installed on the device for this to be possible</p>
                    <p className={styles.sectionSubtitle}>4.2.2 To track your family member or any person under your guardianship</p>
                    <p className={styles.sectionSubtitle}>4.2.3 To track devices you own which are distributed to your employees</p>
                    <p className={styles.sectionSubtitle}>4.2.4 To test our Service by tracking your smart phone or tablet.</p>
                    <p className={styles.sectionSubtitle}>4.3 You are solely responsible to have obtained the permission of the person whose smart phone or tablet is being tracked prior to use of Device Tracker Plus if you are required to do so under any applicable laws of your local jurisdiction.</p>
                    <p className={styles.sectionSubtitle}>4.4 You acknowledge and represent that you shall not use Our Services with the intentions to infringe the privacy of any person. If you believe that your privacy rights are infringed without your consent and authorization, you should immediate notify us with the proof of such infringement.</p>
                    <p className={styles.sectionSubtitle}>4.5 You are responsible to ensure that you are in strict compliance with the Terms and Conditions and the applicable laws of your local jurisdiction.</p>
                    <p className={styles.sectionSubtitle}>4.6 You must not use the Device Tracker Plus in any way which is inappropriate, illegal, or contrary to the Terms and Conditions, or which amounts to the infringement of the privacy of any person.</p>
                    <p className={styles.sectionSubtitle}>4.7 Before using Device Tracker Plus you must pay the Subscription Fees for the package you selected.</p>
                    <p className={styles.sectionSubtitle}>4.8 We will provide you with all applicable features of Device Tracker Plus once the Subscription Fee has been paid, however we reserve the right to modify such features at any time at our sole discretion. Applicable features will be dependent on the package selected at the time of subscription.</p>
                    <p className={styles.sectionSubtitle}>4.9 You acknowledge that positional accuracy may be affected by several technical factors that are not within our control and that we cannot guarantee to that effect. We own no liability for losses, costs, expense or your efforts caused, as a consequence of your reliance upon the report of your mobile position tracked by us.</p>
                    <h4 className={styles.sectionTitleOrange}>5. User Account and Members Area</h4>
                    <p className={styles.sectionSubtitle}>5.1 To access Device Tracker Plus you must provide us with your registration information and pay the Subscription Fee.</p>
                    <p className={styles.sectionSubtitle}>5.2 Your registration information includes your email address & a chosen password. Upon submission of registration information and payment, we will send you an email containing your username and a link to your online account.</p>
                    <p className={styles.sectionSubtitle}>5.3 All information relating to you will be handled and stored in accordance with our Privacy Policy. The email address and password is personal to you and you are responsible to maintain the confidentiality of them. You will ensure that your computer or device that you are using to access your account is free from virus and is adequately protected from any malignant components.</p>
                    <p className={styles.sectionSubtitle}>5.4 You acknowledge that you are responsible for all activities carried out under your account. You should immediately contact us in case of any breach of confidentiality of your account.</p>
                    <h4 className={styles.sectionTitleOrange}>6. Cancellation & Refunds</h4>
                    <p className={styles.sectionSubtitle}>6.1 You have the right to cancel your subscription at any time. If you cancel your subscription within the first 24 hours of joining the service and request a refund, your initial subscription fee will be refunded to you in full. Please note however that if the app has been installed on a device your right to a refund will be void. Active subscriptions can be cancelled anytime up to 24 hours before the scheduled renewal date by using the following methods:</p>
                    <ol className={styles.dottedList}>
                        <li>Emailing our Support Desk: <a href="/help/" className={styles.linkOrange}> HERE</a></li>
                        <li>Using our auto-cancel service from your account</li>
                    </ol>
                    <p className={styles.sectionSubtitle}>Once you have cancelled your service all subscription features will then terminate at the end of your billing cycle. If a subscription is cancelled within the 24-hour period of its scheduled renewal date, the account will be charged for the next subscription period. In this case, your service will continue until the next payment is due, at which point it will end. Note: When you purchase a subscription service such as Device Tracker Plus, you agree to a rolling subscription with monthly billing. You can stop making payments at any time by cancelling your subscription but, beyond the initial 24-hour cooling off period, you will not receive a refund for payments already made, this is irrespective of whether you have used the service during this period or not.</p>
                    <p className={styles.sectionSubtitle}>A receipt for your initial payment will be sent to the email address used to create your account, if you require a monthly receipt for your payment you will need to request this each month via the contact page.</p>
                    <h4 className={styles.sectionTitleOrange}>7. Our liability</h4>
                    <p className={styles.sectionSubtitle}>7.1 If we fail to comply with these terms and conditions, our maximum aggregate liability shall not, in any circumstances, be in excess of the Subscription Charges.</p>
                    <p className={styles.sectionSubtitle}>7.2 Subject to clause 10.3, we will not be liable for losses that result from our failure to comply with these terms and conditions that fall into the following categories:</p>
                    <p className={styles.sectionSubtitle}>7.2.1 loss of income or revenue;</p>
                    <p className={styles.sectionSubtitle}>7.2.2 loss of business;</p>
                    <p className={styles.sectionSubtitle}>7.2.3 loss of profits; or</p>
                    <p className={styles.sectionSubtitle}>7.2.4 loss of anticipated savings.</p>
                    <p className={styles.sectionSubtitle}>7.3 Nothing in this agreement excludes or limits our liability for:</p>
                    <p className={styles.sectionSubtitle}>7.3.1 death or personal injury caused by our negligence;</p>
                    <p className={styles.sectionSubtitle}>7.3.2 fraud or fraudulent misrepresentation;</p>
                    <p className={styles.sectionSubtitle}>7.3.3 any other matter for which it would be illegal for us to exclude or attempt to exclude our liability.</p>
                    <p className={styles.sectionSubtitle}>7.3.4 We do not give any warranty for any goods or services accessed through, or displayed on, our site.</p>
                    <h4 className={styles.sectionTitleOrange}>8. Force Majeure</h4>
                    <p className={styles.sectionSubtitle}>8.1 We shall not be liable for our failure to provide you with all or any features of Our Services if such breach is caused by Natural Force Majeure Event such as, without limitation, an act of God, system downtime, lockouts, accidents, cyber attacks, or any event beyond our reasonable control. If such Force Majeure Event continues for a period longer than fifteen (15) days, you may opt to refund the Subscription Fees and notify us of your decision to that effect.</p>
                    <h4 className={styles.sectionTitleOrange}>9. Modifications and Amendments</h4>
                    <p className={styles.sectionSubtitle}>9.1 We have right to make amendments or modification with reference to following without prior notice to you:</p>
                    <p className={styles.sectionSubtitle}>9.1.1 Terms and Conditions</p>
                    <p className={styles.sectionSubtitle}>9.1.2 Subscription Price</p>
                    <p className={styles.sectionSubtitle}>9.1.3 Features of Our Services</p>
                    <p className={styles.sectionSubtitle}>9.1.4 The modified version of the Terms and Conditions will be applicable immediately upon publication thereof on the Website.</p>
                    <p className={styles.sectionSubtitle}>9.1.5 We will have no liability for aforementioned amendments and modifications. You are strongly recommended to review the Terms and Conditions, Privacy Policy and Pricing Page frequently. Your failure to review the pages will not waive you from your obligations and liabilities arising from aforementioned changes.</p>
                    <h4 className={styles.sectionTitleOrange}>10. Written communication</h4>
                    <p className={styles.sectionSubtitle}>10.1 Applicable laws require that some of the information or communications we send to you should be in writing. When using our site, you accept that communication with us will be mainly electronic. We will contact you by e-mail or provide you with information by posting notices on our website. For contractual purposes, you agree to this electronic means of communication and you acknowledge that all contracts, notices, information and other communications that we provide to you electronically comply with any legal requirement that such communications be in writing.</p>
                    <h4 className={styles.sectionTitleOrange}>11. Severability</h4>
                    <p className={styles.sectionSubtitle}>11.1 If any court or competent authority decides that any of the provisions of these terms and conditions are invalid, unlawful or unenforceable to any extent, the term will, to that extent only, be severed from the remaining terms, which will continue to be valid to the fullest extent permitted by law.</p>
                    <h4 className={styles.sectionTitleOrange}>12. Entire agreement</h4>
                    <p className={styles.sectionSubtitle}>12.1 These terms and conditions and any document expressly referred to in them constitute the whole agreement between us and supersede all previous discussions, correspondence, negotiations, previous arrangement, understanding or agreement between us relating to the subject matter of these terms and conditions. We each acknowledge that, in entering into these terms and conditions, neither of us relies on, or will have any remedies in respect of, any representation or warranty (whether made innocently or negligently) that is not set out in these terms and conditions or the documents referred to in them. Nothing in this clause limits or excludes any liability for fraud.</p>
                    <h4 className={styles.sectionTitleOrange}>13. Intellectual Property</h4>
                    <p className={styles.sectionSubtitle}>13.1 All copyrights, patents, trademarks, designs, ideas, know-how, concepts, graphics, text, drawings, information, content, technology, tools, apps, technical information, or material used in Our Services (“Intellectual Property”) shall be our exclusive property. No part of the Intellectual Property can be copied, used or exploited in any manner without our written approval.</p>
                    <h4 className={styles.sectionTitleOrange}>14. Contacting us.</h4>
                    <p className={styles.sectionSubtitle}>14.1 If at any point during the duration of your subscription you need to get in touch with us, you can do so by going <a href="/help/" className={styles.linkOrange}> HERE</a>.</p>
                    <h4 className={styles.sectionTitleOrange}>15. Cancellation & Refunds</h4>
                    <p className={styles.sectionSubtitle}>For your convenience this page lays out for you the Cancellation & Refund Policy for the Device Tracker Plus subscription service; please note that our Cancellation & Refund policy is point 6. of our Terms of Service, and you should understand that by continuing to make payment, you agree to be bound by this policy. Please read this policy carefully and make sure that you understand it, before subscribing. Your failure to read this Cancellation & Refund policy will not be an acceptable ground to exclude you from your liabilities arising from the policy. </p>
                    <p className={styles.sectionSubtitle}>You should print a copy of this policy for future reference. </p>
                    <p className={styles.sectionSubtitle}>By accepting our Terms of Service you consent to Device Tracker Plus (Boston Digital Limited) supplying our services to you immediately, and that by actively giving this express consent you acknowledge that you waive the right to action any statutory consumer cancellation rights. </p>
                    <p className={styles.sectionSubtitle}>We reserve the right to amend this policy at any time by giving you notice by posting the amended policy on our site. However, please note that you will be subject to the policy in force at the time that you subscribed, unless any change to this policy is required to be made by law or governmental authority (in which case it will apply to orders previously placed by you). </p>
                    <p className={styles.sectionSubtitle}>You have the right to cancel your subscription at any time. If you cancel your subscription within the first 24 hours of joining the service and request a refund, your initial subscription fee will be refunded to you in full. Please note however that if the app has been installed on a device your right to a refund will be void. Active subscriptions can be cancelled anytime up to 24 hours before the scheduled renewal date by using the following methods: </p>
                    <ol className={styles.dottedListOrangeBold}>
                        <li>Emailing our Support Desk </li>
                        <li>Using our auto-cancel service from your account </li>
                    </ol>
                    <p className={styles.sectionSubtitle}>Once you have cancelled your service all subscription features will then terminate at the end of your billing cycle. If a subscription is cancelled within the 24-hour period of its scheduled renewal date, the account will be charged for the next subscription period. In this case, your service will continue until the next payment is due, at which point it will end. </p>
                    <p className={styles.sectionSubtitle}>Note: When you purchase a subscription service such as Device Tracker Plus, you agree to a rolling subscription with monthly billing. You can stop making payments at any time by cancelling your subscription but, beyond the initial 24-hour cooling off period, you will not receive a refund for payments already made (unless agreed by the company); this is irrespective of whether you have used the service during this period or not. </p>
                    <p className={styles.sectionSubtitle}>A receipt for your initial payment will be sent to the email address used to create your account, if you require a monthly receipt for your payment you will need to request this each month via the contact page. </p>
                </div>
            </div>
        </>
    )
}

export default Terms;