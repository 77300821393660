import style from '../../styles/track.module.css';
import StartTrackingButton from '../sharedComponents/StartTrackingButton';
import createAccount from '../../assets/images/createAccount.webp';
import darkChevron from '../../assets/images/darkChevron.webp';
import download from '../../assets/images/download.webp';
import startTracking from '../../assets/images/startTracking.webp';


const HowItWorks = () => {
    return (
        <section className={style.bgSlade}>
            <div className='maxScreen'>
                <div className="secondaryContainer">
                    <h1 className="smallHeading midVerticalMargin">How It works</h1>
                    <h2 className="secondaryHeading">Getting started with Device Tracker Plus is really <span className="hightLightText">quick</span> and <span className="hightLightText">easy</span>.</h2>
                </div>
                <div className={style.stepContainer}>
                    <div className={style.worksStep}>
                        <div className={style.stepContainer}>
                            <img src={createAccount} alt="createAccount_image" />
                            <p className="paragraph marginLeft smallVerticalMargin">Sign up online</p>
                        </div>
                        <img src={darkChevron} alt="greyArrow" className="mobileDisabled" />
                    </div>
                    <div className={style.worksStep}>
                        <div className={style.stepContainer}>
                            <img src={download} alt="download_image" />
                            <p className="paragraph marginLeft smallVerticalMargin">Register device</p>
                        </div>

                        <img src={darkChevron} alt="greyArrow" className="mobileDisabled" />
                    </div>
                    <div className={style.worksStep}>
                        <div className={style.stepContainer}>
                            <img src={startTracking} alt="starttracking_image" />
                            <p className="paragraph marginLeft smallVerticalMargin">Start Tracking</p>
                        </div>

                    </div>
                </div>
                <div className="buttonContainer">
                    <StartTrackingButton title="Start Tracking Now" style={`trackingButton`} />
                </div>
            </div>
        </section>
    );
}

export default HowItWorks;