import React from 'react';
import styles from '../styles/cancel.module.css';
import CancelConfirm from '../components/sharedComponents/CancelConfirm';
import { Helmet } from 'react-helmet';

const CancelConfirmPage = () => {

    return (
        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
            </Helmet>
            <div className={styles.mainContainer}>
                <div className={styles.cancelCard}>
                    <h1 className={styles.cancelHeading}>Cancel account</h1>
                    <div style={{ color: '#F15223', textAlign: 'center' }}>
                        <h2 className={styles.cancelSubtitle}>Your account has now been cancelled.</h2>
                        <h2 className={styles.cancelSubtitle}>Thank you for using Device Tracker Plus</h2>
                        <a href="/" className={styles.buttonContainer}><button className={styles.submitButton}>Go to Home page</button></a>
                        <CancelConfirm />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CancelConfirmPage;