import style from '../../styles/track.module.css';
import rays_feature from '../../assets/images/rays_feature.webp';
import FeaturesCarousel from '../sharedComponents/FeaturesCarousel';
import { featurelist_Std } from '../../data/featureList_Std';

const Features = () => {
    return (
        <section className={style.main}>
            <div className='maxScreen'>
                <div className="secondaryContainer displayRow">
                    <h1 className="secondaryHeading">We've upgraded our tracking accuracy to keep you, and those you care about, even safer.</h1>
                    <img src={rays_feature} width={52} height={79} alt="rays_image" className="mobileDisabled" />
                </div>
                <FeaturesCarousel features={featurelist_Std} />
            </div>
        </section>
    );
}

export default Features;