import useMediaQuery from '../../hooks/useMediaQuery';

const HeroImageComponent = ({desktopImage, mobileImage}) => {

    const isAboveMediumScreens = useMediaQuery("(min-width: 1025px)");
    
    return (
        <div className="heroImageContainer">
            <img loading="lazy" className='heroImage' src={isAboveMediumScreens ? desktopImage : mobileImage} alt="dtp_app_image" />
        </div>
    );
}

export default HeroImageComponent;