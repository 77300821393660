import React, { useState } from 'react';
import styles from '../../styles/nav.module.css';
import useMediaQuery from '../../hooks/useMediaQuery';
import logo from '../../assets/images/logo.webp';
import { Bars3Icon, XMarkIcon, ChevronDoubleDownIcon, ChevronDoubleUpIcon } from '@heroicons/react/24/solid';


const NavBar = () => {

  const isAboveMediumScreens = useMediaQuery("(min-width: 762px)");
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className={styles.nav}>
      <div className={styles.navMainContainer}>
        <a href="/"><img src={logo} width={180} height={78} alt="logo" className={styles.logo} /></a>

        {isAboveMediumScreens ?
          <ul className={styles.desktopNavList}>
            <li>
              <a className={styles.link} href={'/'}>Home</a>
            </li>
            {/* <li

              onClick={() => setIsOpen((prev) => !prev)}
            >
              <div className={styles.featureMenuContainer}>
                <p className={styles.link}>Features {
                  !isOpen ?
                    (<ChevronDoubleDownIcon className={styles.featureIcons} />) :
                    (<ChevronDoubleUpIcon className={styles.featureIcons} />)
                }</p>
                {isOpen && (
                  <div className={styles.dropDownContainer}>
                    <div className={styles.dropDownItem}>
                      <a href={'/familyTracking'}>Family Tracking</a>
                    </div>
                    <div className={styles.dropDownItem}>
                      <a href={'/locationSharing'}>Loaction Sharing</a>
                    </div>
                    <div className={styles.dropDownItem}>
                      <a href={'/emergencyAlert'}>Emergency Alert</a>
                    </div>
                    <div className={styles.dropDownItem}>
                      <a href={'/track'}>Mobile Number Tracker</a>
                    </div>
                  </div>
                )}
              </div>
            </li> */}
            <li >
              <a className={styles.link} href={'/whoWeAre'}>Who we are</a>
            </li>
            <li >
              <a className={styles.link} href={'/faqs'}>FAQ</a>
            </li>
            <li >
              <a className={styles.link} href={'/help'}>Help</a>
            </li>
          </ul>

          :
          <div className={styles.navMobileButton} id="navMobile" title="navMobile"
            onClick={() => setIsMenuToggled(!isMenuToggled)}
          >
            {
              !isMenuToggled ? <Bars3Icon className={styles.mobileMenuIcon} /> : <XMarkIcon className={styles.mobileMenuIcon} />
            }
          </div>
        }
      </div>
      {/* Mobile Menu */}
      {!isAboveMediumScreens && isMenuToggled && (
        <div className={styles.mobileNav}>
          <ul className={styles.mobileNavMenuList}>
            <li >
              <a className={styles.mobileNavItem} href={'/'}>Home</a>
            </li>
            <li

              onClick={() => setIsOpen((prev) => !prev)}
            >
              <div className={styles.mobileFeatureMenuContainer}>
                <p className={styles.mobileNavItem}>Features {
                  !isOpen ?
                    (<ChevronDoubleDownIcon className={styles.featureIcons} />) :
                    (<ChevronDoubleUpIcon className={styles.featureIcons} />)
                }</p>
                {isOpen && (
                  <div className={styles.mobileDropDownContainer}>
                    <div className={styles.mobileDropDownItem}>
                      <a className={styles.mobileNavItem} href={'/familyTracking'}>Family Tracking</a>
                    </div>
                    <div className={styles.mobileDropDownItem}>
                      <a className={styles.mobileNavItem} href={'/locationSharing'}>Loaction Sharing</a>
                    </div>
                    <div className={styles.mobileDropDownItem}>
                      <a  className={styles.mobileNavItem} href={'/emergencyAlert'}>Emergency Alert</a>
                    </div>
                    <div className={styles.mobileDropDownItem}>
                      <a className={styles.mobileNavItem} href={'/track'}>Mobile Number Tracker</a>
                    </div>
                  </div>
                )}
              </div>
            </li>
            <li >
              <a href={'/whoWeAre'} className={styles.mobileNavItem}>Who we are</a>
            </li>
            <li >
              <a href={'/faqs'} className={styles.mobileNavItem} >FAQ</a>
            </li>
            <li >
              <a href={'/help'} className={styles.mobileNavItem}>Help</a>
            </li>

          </ul>
        </div>
      )}
    </nav>
  );
}

export default NavBar;