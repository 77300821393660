export const familyTrackingFeaturesList = [
    {
        id: '1',
        title: 'Real-Time Location Sharing',
        content: 'Our family locator app has accurate, real-time location tracking, so you can track your child’s whereabouts instantly. Track them on the move with live speed data or check if they’ve reached their destination safely.',
        imgName: 'locationSharingCarousell'
    },
    {
        id: '2',
        title: '30-Day Location History',
        link: '/locationSharing',
        partContent: 'With our accurate ',
        linkContent: 'location sharing app',
        content: ', you can track the routes and locations your loved ones and their devices took for the past month. GPS tracking is perfect for you to be able to give them the independence they want while maintaining your peace of mind.',
        imgName: 'locationHistoryCarousel'
    },
    {
        id: '3',
        title: 'Geofence Alerts',
        content: "Set virtual boundaries around key places like home, school, or a friend’s house. Get notified when your child enters or leaves these safe zones, so you know exactly where they are and when they’ve arrived safely.",
        imgName: 'familyCarousel'
    },
    {
        id: '4',
        title: 'Track Multiple Devices',
        content: 'Device Tracker Plus makes it simple to track multiple devices from a single account. If you’re looking to keep track of family members, such as children or elderly relatives, our cross-platform support means everyone you care about is just a click away, whether they’re on iOS or Android ',
        imgName: 'trackMultiDevicesCarousell'
    },
    {
        id: '5',
        title: 'Emergency Alerts',
        link: '/emergencyAlert',
        partContent: 'In an emergency, your child can reach out for help with just a tap. The app will send an instant ',
        linkContent: 'emergency alert',
        content: '  to all devices in the group, so you can track their location and take action.',
        imgName: 'alertCarousel'
    },
    {
        id: '6',
        title: 'Instant Messaging',
        link: '/track',
        partContent: '',
        linkContent: "Track a phone's location",
        content: " and communicate instantly, all in one app. Device Tracker Plus is the ultimate tool to keep your family safe and connected. Send messages, photos, or updates to loved ones directly from the app.",
        imgName: 'messegesCarousel'
    },
];