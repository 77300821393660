import React, { useState, lazy, Suspense } from 'react';
import familyHero_deskImage from '../../assets/images/heroMultiDevice_desk.webp';
import familyHero_mobileImage from '../../assets/images/heroMultiDevice_mobile.webp';
import desktopImage from '../../assets/images/mainBgSmall.webp';
import mobileImage from '../../assets/images/bgMobile.webp';
import style from '../../styles/track.module.css';
import Spinner from '../sharedComponents/Spinner';

const PhoneVerificationComponent = lazy(() => import('../sharedComponents/PhoneVerification'));
const HeroImageComponent = lazy(() => import('../sharedComponents/HeroImageComponent'));

const LocationSharingHero = () => {

    const [showSearchInput, setShearchInput] = useState(true);

    const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage;

    const togglePhoneVerificationPopup = (e) => {
        e.preventDefault();
        setShearchInput(!showSearchInput);
    }
    return (
        <section className="mainBgc" style={{ backgroundImage: `url(${imageUrl})` }}>
            <div className="mainContainer">
                <div className="heroContainer">
                    <h1 className="heroHeading">Locate, Protect, and Connect with A <span className="hightLightText">Location Sharing App</span></h1>
                    <p className={style.trackSubtitle}>Stay connected with the people who matter most, no matter where you are. Our location sharing app lets you share your live location with friends, family, and loved ones. Designed for both iPhone and Android, Device Tracker Plus keeps everyone connected without constant phone calls or messages.</p>
                    <Suspense fallback={<Spinner />}>
                        {showSearchInput && <PhoneVerificationComponent showPopUp={togglePhoneVerificationPopup} />}
                    </Suspense>
                </div>
                <div>
                    <Suspense fallback={<Spinner />}>
                        <HeroImageComponent desktopImage={familyHero_deskImage} mobileImage={familyHero_mobileImage} />
                    </Suspense>
                </div>
            </div>
        </section>
    );
}

export default LocationSharingHero;