import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useNavigate } from "react-router-dom";

const QRCode = ({ link }) => {

    const [deepLink, setDeepLink] = useState("");

       const navigate = useNavigate();

        useEffect(() => {
            if (link) {
                setDeepLink(link);
            }else{
                setDeepLink('https://www.devicetrackerplus.com/storeDetection') 
            }
        }, [link, navigate]);

    console.log("What is Link: ", link);
    return (
        <QRCodeSVG value={deepLink} level='M' title='Store QRcode' />
    )
}

export default QRCode;