import style from '../../styles/track.module.css';
import rays_feature from '../../assets/images/rays_feature.webp';
import FeaturesCarousel from '../sharedComponents/FeaturesCarousel';

const CampaignFeatures = ({ sectionTitle, features }) => {
    return (
        <section className={style.main}>
            <div className='maxScreen'>
                <div className={style.featuremainContainer}>
                    <h1 className={style.trackFeatureHeading}>{sectionTitle}</h1>
                    <img src={rays_feature} width={52} height={79} alt="rays_image" className="mobileDisabled" />
                </div>
                <FeaturesCarousel features={features} />
            </div>
        </section>
    );
}

export default CampaignFeatures;